import axios from "@/plugins/http.js";
class lecturerApi {
  getTeacherList(params) {
    //获取讲师库列表
    return axios.get(`/teacher-list-in?${params}`);
  }
  getAuditList(params) {
    //获取讲师审核列表
    return axios.get(`/teacher/audit-list?${params}`);
  }
  getStopStart(params) {
    //讲师下架/审核
    return axios.post(`/teacher/stop-start`, params);
  }
  getAuditLogList(params) {
    //获取审核记录
    return axios.get(`/audit-log-list?${params}`);
  }
  getTeacher(params) {
    //新建讲师
    return axios.post(`/teacher`, params);
  }
  getPutTeacher(params) {
    //编辑讲师
    return axios.put(`/teacher`, params);
  }
  getTeacherDetail(params) {
    //管理端获取讲师详情
    return axios.get(`/teacher/${params}`);
  }
  getClientTeacherDetail(params) {
    //学员端获取讲师详情
    return axios.get(`/client/teacher/${params}`);
  }
  getDeleteTeacher(params) {
    //删除讲师
    return axios.delete(`/teacher/delete/${params}`);
  }
  getRelevancyTeacher(params,id) {
    //关联讲师
    return axios.post(`/course-teacher/${id}`, params);
  }
  getRelevancyTeacherList(params) {
    //获取关联讲师列表
    return axios.get(`/teacher-list-out?${params}`);
  }
  getRelatedCoursesList(params) {
    //获取关联课程
    return axios.get(`/teacher/course-list?${params}`);
  }
  getPassRejection(id, params) {
    //通过/驳回
    return axios.post(`/teacher/audit/${id}`, params);
  }
  getClientTeacher(params) {
    //获取学员端讲师列表
    return axios.get(`/client/teacher-list?${params}`);
  }
  getClientTeacherCourse(params) {
    //获取学员端讲师关联课程
    return axios.get(`/client/teacher-info/course-list?${params}`);
  }
}
export default new lecturerApi();
