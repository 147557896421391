<template>
  <div class="wxq_content">
    <div class="wxq_lecturer_main">
      <div class="wxq_lecturer_detail" v-for="item in dataList" :key="item.id">
        <div class="wxq_detail_top">
          <img :src="item.avatar ? item.avatar : defaultAvatar" alt="" />
          <p>{{ item.teaName }}</p>
          <p :title="item.teaTitle">{{ item.teaTitle }}</p>
          <p>创建于 {{ item.createTime }}</p>
        </div>
        <div class="wxq_detail_footer">
          <div class="wxq_footer_box">
            <p
              :class="{
                color_orange: item.state == '审核中',
                color_green: item.state == '已发布',
                color_red: item.state == '已下架',
              }"
            >
              {{ item.state }}
            </p>
            <p>状态</p>
          </div>
          <div class="wxq_footer_box">
            <p>{{ item.creatorName }}</p>
            <p>创建人</p>
          </div>
          <div
            class="wxq_footer_box wxq_style"
            @click="getRelatedCourses(item)"
          >
            <p>{{ item.courseNum ? item.courseNum : 0 }}</p>
            <p>关联课程</p>
          </div>
        </div>
        <!--基础设置-->
        <div class="wxq_basic_setting">
          <el-dropdown
            v-if="type == 'viewDetail'"
            trigger="click"
            @command="handleCommand($event, item.teaId, item)"
          >
            <span class="el-dropdown-link">
              <i class="el-icon-more el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="viewDetail">
                <img
                  src="@/assets/img/backStageImg/myCourse/yulan-icon.png"
                  alt=""
                />
                查看详情
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown
            v-else
            trigger="click"
            @command="handleCommand($event, item.teaId, item)"
          >
            <span class="el-dropdown-link">
              <i class="el-icon-more el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="viewDetail">
                <img
                  src="@/assets/img/backStageImg/myCourse/yulan-icon.png"
                  alt=""
                />
                查看详情
              </el-dropdown-item>
              <el-dropdown-item
                command="compile"
                :disabled="item.state == '已发布' || item.state == '待审核'"
              >
                <img
                  src="@/assets/img/backStageImg/myCourse/compile-icon.png"
                  alt=""
                />
                编辑
              </el-dropdown-item>
              <el-dropdown-item
                command="publish"
                :disabled="item.state == '已发布' || item.state == '待审核'"
              >
                <img
                  src="@/assets/img/backStageImg/myCourse/send-icon.png"
                  alt=""
                />
                提交审核
              </el-dropdown-item>
              <el-dropdown-item command="history">
                <img src="@/assets/img/backStageImg/history-icon.png" alt="" />
                审核历史
              </el-dropdown-item>
              <el-dropdown-item
                command="undercarriage"
                :disabled="
                  item.state == '已下架' ||
                  item.state == '已驳回' ||
                  item.state == '未发布'
                "
              >
                <img
                  src="@/assets/img/backStageImg/myCourse/sold-out-icon.png"
                  alt=""
                />
                下架
              </el-dropdown-item>
              <el-dropdown-item
                command="delete"
                :disabled="item.state == '已发布' || item.state == '待审核'"
              >
                <img
                  src="@/assets/img/backStageImg/myCourse/delete-icon.png"
                  alt=""
                />
                删除
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <pagination
      v-if="totalCount > 0"
      :pageSize="pageSize"
      :total="totalCount"
      @pageChange="pageChange"
    />
    <el-dialog
      class="dialog_wrapper"
      title="讲师详情"
      v-if="isDetail"
      :visible.sync="isDetail"
      :modal-append-to-body="false"
      top="15vh"
      width="650px"
    >
      <lecturer-detail :id="teaId"></lecturer-detail>
    </el-dialog>
    <el-dialog
      class="dialog_wrapper"
      :title="teaName + '的相关课程'"
      v-if="isRelated"
      :visible.sync="isRelated"
      :modal-append-to-body="false"
      top="10vh"
      width="914px"
    >
      <related-courses
        :id="teaId"
      ></related-courses>
    </el-dialog>
    <!--审核记录-->
    <el-drawer
      :visible.sync="isDrawerVisible"
      :with-header="false"
      :wrapperClosable="true"
    >
      <div class="gl_drawer">
        <div class="gl_drawer_title">
          <span
            class="gl_state"
            :class="{
              color_orange: currentStateName == '审核中',
              color_green: currentStateName == '已发布',
              color_red: currentStateName == '已驳回',
              color_gray: currentStateName == '已下架' || '未发布',
            }"
            >{{ currentStateName }}</span
          >
          <h2 :title="lecturerTitle">{{ lecturerTitle }}</h2>
        </div>
        <div class="gl_drawer_cont">
          <p class="gl_drawer_t">审批历史</p>
          <ul v-if="auditCount > 0">
            <li v-for="item in auditList" :key="item.logId">
              <span class="gl_audit_user">{{ item.operatorName }}</span
              >{{ item.operation
              }}<span class="gl_audit_time">{{ item.operateTime }}</span>
              <p v-if="item.remark" class="gl_audit_info">
                "{{ item.remark }}"
              </p>
            </li>
          </ul>
          <p v-else class="gl_empty">——— 暂无审批数据 ———</p>
        </div>
        <pagination
          class="hh_page"
          v-if="auditCount > recordData.pageSize"
          :pageSize="recordData.pageSize"
          :total="auditCount"
          @pageChange="auditPageChange"
          ref="child"
        />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import lecturerDetail from "./lecturerDetail";
import relatedCourses from "./relatedCourses";
import Pagination from "@/components/backStageComponent/pagination";
import API from "@apis/backStageEndAPI/lecturerAPI";
import CommonUtils from "@utils";
export default {
  name: "lecturerMain",
  props: ["dataList", "totalCount", "pageSize", "type"],
  components: {
    lecturerDetail,
    Pagination,
    relatedCourses,
  },
  data() {
    return {
      defaultAvatar: require("@/assets/img/backStageImg/user-icon.png"),
      teaId: null, //讲师id
      teaName: "",
      isDetail: false, //详情开关
      isRelated: false, //关联课程开关
      currentData: {},
      isDrawerVisible: false, //审核历史开关
      stateMap: "",
      currentRow: "",
      currentState: "",
      currentStateName: "",
      lecturerTitle: "",
      auditCount: 0,
      auditList: [],
      recordData: {
        itemId: "",
        itemType: 2,
        page: 1,
        pageSize: 10,
      },
    };
  },
  mounted() {},
  methods: {
    getRelatedCourses(item) {
      //获取关联课程
      this.teaName = item.teaName;
      this.teaId = item.teaId;
      this.isRelated = true;
    },
    handleCommand(val, id, item) {
      //基础设置
      if (val == "viewDetail") {
        //详情
        this.teaId = id;
        this.isDetail = true;
      } else if (val == "compile") {
        //编辑
        this.$emit("getCompile", id);
      } else if (val == "publish") {
        //发布
        this.$confirm("确定要提交审核该讲师吗？", {})
          .then((res) => {
            let params = {
              teaId: id,
              state: 2,
            };
            this.getStopStart(params);
          })
          .catch(() => {});
      } else if (val == "history") {
        this.getAuditLogList(item);
      } else if (val == "undercarriage") {
        //下架
        this.$confirm("确定要下架该讲师吗？", {})
          .then((res) => {
            let params = {
              teaId: id,
              state: 9,
            };
            this.getStopStart(params);
          })
          .catch(() => {});
      } else if (val == "delete") {
        this.$confirm("确定要删除该讲师吗？", {})
          .then((res) => {
            this.getDeleteTeacher(id);
          })
          .catch(() => {});
      }
    },
    async getAuditLogList(item) {
      //获取审核记录
      this.currentRow = item;
      this.currentStateName = item.state;
      this.lecturerTitle = item.teaName;
      this.recordData.itemId = item.teaId;
      let data = await API.getAuditLogList(
        CommonUtils.parseToParams(this.recordData)
      );
      this.auditList = data.dataList;
      this.auditCount = data.rowCount;
      this.isDrawerVisible = true;
    },
    auditPageChange(val) {
      //审核记录页面跳转
      this.recordData.page = val;
      this.getAuditLogList(this.currentRow);
    },
    getDeleteTeacher(id) {
      API.getDeleteTeacher(id).then((res) => {
        this.$emit("updateLecturer");
      });
    },
    getStopStart(params) {
      API.getStopStart(params).then((res) => {
        this.$emit("updateLecturer");
      });
    },
    pageChange(val) {
      //页面跳转
      this.$emit("pageChange", val);
    },
  },
};
</script>

<style scoped lang="scss">
.wxq_content {
  padding-bottom: 20px;
  .color_gray {
    color: #dcdcdc;
  }
  .wxq_lecturer_main {
    display: flex;
    flex-wrap: wrap;
    .wxq_lecturer_detail {
      position: relative;
      overflow: hidden;
      margin: 0 8px 18px;
      padding: 10px;
      width: 264px;
      height: 280px;
      border-radius: 24px;
      background: #fff;
      box-shadow: 0 6px 58px 0 rgba(196, 203, 214, 0.1);
      .wxq_detail_top {
        width: 100%;
        height: 186px;
        background: #f4f9fd;
        border-radius: 24px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        img {
          width: 66px;
          height: 66px;
          border-radius: 50%;
        }
        p:first-of-type {
          font-size: 16px;
          font-weight: 500;
          margin: 6px;
        }
        p:nth-of-type(2) {
          width: 80%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: center;
        }
        p:last-of-type {
          font-size: 12px;
          color: #91929e;
          margin-top: 6px;
        }
      }
      .wxq_detail_footer {
        display: flex;
        margin-top: 20px;
        height: 48px;
        .wxq_footer_box {
          padding: 0 8px;
          width: 33%;
          height: 42px;
          border-right: 1px solid #f1f1f1;
          text-align: center;
          p {
            overflow: hidden;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          p:first-of-type {
            color: #3f8cff;
            font-size: 16px;
          }
          p:last-of-type {
            color: #91929e;
            font-size: 12px;
            line-height: 24px;
          }
        }
        .wxq_footer_box:last-of-type {
          border: none;
        }
        .wxq_style {
          cursor: pointer;
        }
      }
      .wxq_basic_setting {
        position: absolute;
        top: 22px;
        right: 22px;
        padding: 8px 0 8px 9px;
        width: 36px;
        height: 36px;
        border-radius: 12px;
        background: #ffffff;
        cursor: pointer;
        .el-dropdown {
          color: #0a1629;
          font-weight: 400;
          .el-icon--right {
            margin-left: 2px;
            font-size: 16px;
            transform: rotate(90deg);
          }
        }
      }
    }
  }
  .wxq_course_detail:hover {
    .wxq_detail_footer {
      transform: translateY(90px);
    }
    .wxq_hide_menu {
      transform: translateY(-90px);
    }
  }
  .pagination {
    padding-top: 0;
  }
  .gl_drawer {
    width: 100%;
    box-sizing: border-box;
    .gl_drawer_title {
      position: relative;
      padding: 0 40px 0 70px;
      background: var(--colorBlue);
      color: #fff;
      text-align: center;
      font-size: 16px;
      line-height: 40px;
      h2 {
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .gl_state {
        position: absolute;
        top: 50%;
        left: 8px;
        padding: 4px 8px;
        border-radius: 16px;
        background: #fff;
        font-size: 12px;
        line-height: 100%;
        transform: translateY(-50%);
      }
    }
    .gl_empty {
      color: #666;
      text-align: center;
    }
    li {
      margin-bottom: 16px;
      padding: 8px;
      background: #f7faff;
      .gl_audit_user {
        padding-right: 6px;
        color: var(--colorBlue);
      }
      .gl_empty {
        color: #666;
        text-align: center;
      }
      li {
        margin-bottom: 16px;
        padding: 8px;
        background: #f7faff;
        .gl_audit_user {
          padding-right: 6px;
          color: var(--colorBlue);
        }
        .gl_audit_time {
          float: right;
          color: #666;
        }
        .gl_audit_info {
          padding: 4px;
        }
      }
    }
  }
}
::v-deep.el-dropdown-menu__item {
  display: flex;
  align-items: center;
  img {
    margin-right: 6px;
    width: 22px;
  }
}
</style>
