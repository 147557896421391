<template>
  <!--讲师关联课程-->
  <div class="wxq_container container">
    <div class="wxq_storeroom">
      <div class="table">
        <el-table
          :data="tableData"
          style="width: 100%">
          <el-table-column
            prop="coverUrl"
            label="课程背景图"
            show-overflow-tooltip
            align="center"
            width="180">
            <template slot-scope="scope">
              <img class="course_background" :src="scope.row.coverUrl" alt="">
            </template>
          </el-table-column>
          <el-table-column prop="courseTitle" label="标题" show-overflow-tooltip align="center"
                           width="250"></el-table-column>
          <el-table-column prop="courseType" label="课程类型" show-overflow-tooltip align="center"
                           width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.courseType == 1 ? '直播' : '点播' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="subjectName" label="课程分类" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column prop="state" label="状态" show-overflow-tooltip align="center">
            <template slot-scope="scope">
              <span
                class="gl_state"
                :class="{
              color_orange: scope.row.state == 2,
              color_green: scope.row.state == 1,
              color_red: scope.row.state == 3,
              color_gray: scope.row.state == 9,
            }"
              >{{ stateMap.get(scope.row.state) }}</span
              >
            </template>
          </el-table-column>
        </el-table>
        <pagination v-if="totalCount > 0" :pageSize="form.pageSize" :total="totalCount"
                    @pageChange="pageVal"></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import pagination from '@/components/backStageComponent/pagination';
import API from "@apis/backStageEndAPI/lecturerAPI";
import CommonUtils from "@/utils";
export default {
  name: 'relatedCourses',
  props: ['id'],
  components: {
    pagination
  },
  data() {
    return {
      form: {
        page: 1,//页码
        pageSize: 10,//分页大小
        teaId: '',//讲师id
      },
      totalCount: 0,
      tableData: [],
      stateMap: "",
    };
  },
  mounted() {
    this.initState();
    if (this.id) {
      this.getTableList(this.id)
    }
  },
  methods: {
    getTableList(id) { //获取课件列表
      this.form.teaId = id
      API.getRelatedCoursesList(CommonUtils.parseToParams(this.form)).then(res => {
        this.tableData = res.dataList
        this.totalCount = res.rowCount
      })
    },
    pageVal(val) {
      //分页跳转
      this.form.page = val;
      this.getTableList(this.id)
    },
    initState() {
      this.stateMap = new Map();
      this.stateMap.set(0, "未发布");
      this.stateMap.set(1, "已发布");
      this.stateMap.set(2, "审核中");
      this.stateMap.set(3, "已驳回");
      this.stateMap.set(9, "已下架");
    },
  }
};
</script>

<style scoped lang="scss">
@import 'src/assets/css/backStageEndCSS/myCoursePageMenu';
.course_background {
  height: 80px;
  width: auto;
}
</style>
