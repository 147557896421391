<template>
  <!--讲师详情-->
  <div class="wxq_container">
    <div class="wxq_detail">
      <img :src="detailData.avatar ? detailData.avatar : defaultAvatar" alt="">
      <div class="wxq_detail_datum">
        <p>{{ detailData.teaName }}</p>
        <p>{{ detailData.teaTitle }}</p>
      </div>
    </div>
    <p class="wxq_detail_introduction" v-text="detailData.teaDesc">
    </p>
    <el-form
      class="form"
      :model="formData"
      :rules="rules"
      ref="form"
      :inline="true"
    >
      <el-row v-show="isRejection">
        <el-col :span="24">
          <el-form-item prop="remark">
            <el-input
              type="textarea"
              rows="5"
              v-model.trim="formData.remark"
              placeholder="请输入驳回理由"
              maxlength="2000"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row v-if="type">
      <el-col :span="24" class="dialog_submit" v-show="!isRejection">
        <el-button class="btn btn_light_green" @click="getPass()">
          通过
        </el-button>
        <el-button
          class="btn btn_red"
          @click="isRejection = true">
          驳回
        </el-button>
      </el-col>
      <el-col :span="24" class="dialog_submit" v-show="isRejection">
        <el-button class="btn" @click="isRejection = false">
          取消
        </el-button>
        <el-button
          class="btn btn_red"
          @click="getRejection('form')">
          确认驳回
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import API from "@apis/backStageEndAPI/lecturerAPI";
export default {
  name: "lecturerDetail",
  props: ['id', 'type'],
  data() {
    return {
      defaultAvatar: require('@/assets/img/backStageImg/user-icon.png'),
      detailData: {},
      formData: {
        result: '',
        remark: '',
      },
      isRejection: false,
      rules: {
        remark: [{required: true, message: "请输入驳回理由", trigger: "blur"}],
      },
    }
  },
  mounted() {
    if (this.id) {
      this.getDetail(this.id)
    }
  },
  methods: {
    getPass() {
      this.$confirm("确定要通过审核吗", {})
        .then((res) => {
          this.formData.result = 1
          API.getPassRejection(this.id, this.formData).then((res) => {
            this.$emit('closeDialog')
          });
        })
        .catch(() => {
        });
    },
    getRejection(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.formData.result = 2
          API.getPassRejection(this.id, this.formData).then((res) => {
            this.$emit('closeDialog')
          });
        }else {
          return false;
        }
      });
    },
    getDetail(id) {
      API.getTeacherDetail(id).then(res => {
        if (res && !res.status) {
          this.detailData = res
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.wxq_container {
  ::v-deep.el-form-item {
    width: 100%;
    margin-top: 20px;
    .el-form-item__content {
      display: block;
      padding-left: 0;
      .el-cascader {
        width: 100%;
      }
      .el-input__inner, .el-textarea__inner {
        border-radius: 14px;
      }
      .el-form-item__error {
        padding-left: 0;
      }
    }
  }
  .wxq_detail {
    width: 100%;
    height: 150px;
    background: #F4F9FD;
    border-radius: 24px;
    padding: 18px;
    margin-bottom: 20px;
    display: flex;
    img {
      width: 108px;
      height: 108px;
      border-radius: 50%;
    }
    .wxq_detail_datum {
      margin-left: 15px;
      p:first-of-type {
        font-size: 20px;
        font-weight: 500;
        color: #0A1629;
      }
      p:last-of-type {
        font-size: 14px;
        color: #7D8592;
        line-height: 26px;
      }
    }
  }
  .wxq_detail_introduction {
    font-size: 14px;
    color: #7D8592;
    line-height: 30px;
    white-space: pre-wrap;
  }
}
</style>
